import React, { useState } from "react";
import { Button, CheckBoxField } from "@unite-us/app-components";
import PaginatedTable from "components/Tables/PaginatedTable";
import { useProviders } from "api/core/providerHooks";
import { useOutletContext } from "react-router";

const FeeScheduleScreeningCboTable = () => {
  const feeScheduleScreening = useOutletContext();
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);

  const {
    data: providersResponse,
    isFetching,
    isError,
  } = useProviders({
    filters: { fee_schedule_screenings: feeScheduleScreening.id },
  });

  const { data: providers, paging } = providersResponse || {};

  const tableHeaders = [
    {
      label: (
        <CheckBoxField
          id="cbo-check-all-checkbox"
          label=""
          hideLabel
          showError={false}
          disabled
        />
      ),
      className: "text-xs",
    },
    { label: "Name", className: "w-full" },
  ];

  const tableBody = providers?.map((provider) => ({
    rowId: provider.id,
    rowData: [
      {
        data: (
          <CheckBoxField
            id={`select-checkbox-${provider.id}`}
            label=""
            hideLabel
            showError={false}
            disabled
          />
        ),
        className: "text-xs",
      },
      { data: provider.name },
    ],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue rounded-t-md">
        <h1 className="text-lg content-center">
          Community Based Organizations
        </h1>
        <div className="flex flex-row space-x-4">
          <Button
            id="add-fee-schedule-screening-cbo-button"
            icon={{ name: "Add", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Add CBO"
            data-testid="add-fee-schedule-screening-cbo-button"
            onClick={() => {}}
            disabled
          />
          <Button
            id="remove-fee-schedule-screening-cbo-button"
            icon={{ name: "MinusCircle", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Remove CBO"
            data-testid="remove-fee-schedule-screening-cbo-button"
            onClick={() => {}}
            disabled
          />
        </div>
      </div>
      <PaginatedTable
        headers={tableHeaders}
        body={tableBody}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        paging={paging}
        dataTestId="fee-schedule-screening-cbo-table"
        emptyTableMessage="Click 'Add CBO' to add CBOs"
        errorMessage="Error Fetching Community Based Organizations"
        className="!h-fit"
        emptyTableClassName="py-4"
      />
    </>
  );
};

export default FeeScheduleScreeningCboTable;
