import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "react-query";
import { BarLoader, FilterSingle } from "@unite-us/app-components";
import { getBatchJobStatus } from "../utils/statusUtils";
import getBatchJobDetails from "../utils/getBatchJobDetails";
import { useBatchJobData } from "api/core/batchJobDataHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import BatchProgress from "./BatchProgress";

const BatchDetails = ({
  batchId,
  dateCreated,
  requester,
  action,
  batchSize,
  status,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [filters, setFilters] = useState({ batch_data_id: batchId });
  const [shouldRefetch, setShouldRefetch] = useState(true);
  const queryClient = useQueryClient();

  const {
    data: jobsResponse,
    isFetching,
    isError,
  } = useBatchJobData({
    filters: filters,
    pageNumber: pageNumber,
    pageSize: pageSize,
    queryConfig: shouldRefetch ? { refetchInterval: 10 * 1000 } : {},
  });
  const { data: jobs, paging: jobsPaging } = jobsResponse;

  const { data: totalJobsResponse } = useBatchJobData({
    filters: { batch_data_id: batchId },
    queryConfig: shouldRefetch ? { refetchInterval: 10 * 1000 } : {},
  });
  const { paging: totalJobsPaging } = totalJobsResponse;
  const finishedJobs = totalJobsPaging?.total_count || 0;

  useEffect(() => {
    queryClient.invalidateQueries(["batch_job_data", filters]);
    queryClient.invalidateQueries([
      "batch_job_data",
      { batch_data_id: batchId },
    ]);
  }, [batchId, filters, queryClient]);

  useEffect(() => {
    if (finishedJobs === batchSize) {
      setShouldRefetch(false);
    }
  }, [finishedJobs, batchSize]);

  const tableHeaders = [
    { label: "User" },
    { label: "Status" },
    { label: "Details" },
  ];

  const jobBody = jobs.map((job) => {
    const output = JSON.parse(job.output);
    const error = JSON.parse(job.error);
    const jobStatus = getBatchJobStatus(job, output);
    const jobDetails = getBatchJobDetails(action, output, error);

    return {
      rowId: job.id,
      rowData: [
        { data: output?.invitee_name || output?.employee_name },
        {
          data: jobStatus,
          className: "capitalize",
        },
        {
          style: { whiteSpace: "normal" },
          data: jobDetails,
        },
      ],
    };
  });

  const statusOptions = [
    { label: "All Statuses", value: "" },
    { label: "Successful", value: "successful" },
    { label: "Not Successful", value: "failed" },
    { label: "Skipped", value: "skipped" },
  ];

  return (
    <>
      <div
        data-testid="import-center-jobs-top-header"
        className="pb-4 flex justify-between"
      >
        <div className="w-1/2">
          <div className="flex flex-row relative">
            <h1 className="font-bold text-left w-40">Date Created:</h1>
            <p className="font-bold">{dateCreated}</p>
          </div>
          <div className="flex flex-row relative">
            <h1 className="font-bold text-left w-40">Requester:</h1>
            <p className="font-bold">{requester}</p>
          </div>
          <div className="flex flex-row relative">
            <h1 className="font-bold text-left w-40">Action:</h1>
            <p className="font-bold">{action}</p>
          </div>
        </div>
        <div className="w-1/2">
          {finishedJobs !== 0 ? (
            <BatchProgress
              totalJobs={batchSize}
              finishedJobs={finishedJobs}
              status={status}
            />
          ) : (
            <div data-testid="batch-progress-loader">
              <BarLoader tall />
            </div>
          )}
        </div>
      </div>
      <FilterSingle
        key="batch-details-filter"
        uniqIdPrefx="batch-details-filter"
        name="Batch Status"
        className="pb-4"
        dataTestId="batch-details-filter"
        value={filters.status || ""}
        options={statusOptions}
        onFilterChange={({ value }) => {
          setFilters({ ...filters, status: value });
          setPageNumber(1);
        }}
        filterKey="batchStatusFilter"
      />
      <PaginatedTable
        className={
          "flex flex-col min-w-full max-h-[55vh] h-[55vh] sm-h:h-[150px] md-h:h-auto"
        }
        body={jobBody}
        headers={tableHeaders}
        isFetching={isFetching}
        emptyTableMessage="No operations found"
        pageSize={pageSize}
        pageNumber={pageNumber}
        paging={jobsPaging}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        dataTestId="import-center-jobs-table"
        isError={isError}
        showSpinnerOnEmptyFetch
      />
    </>
  );
};

BatchDetails.propTypes = {
  batchId: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  requester: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  batchSize: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default BatchDetails;
