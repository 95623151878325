import React from "react";
import PropTypes from "prop-types";
import { InputField } from "@unite-us/ui";
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Icon,
  TextField,
  RadioField,
} from "@unite-us/app-components";

const RejectionReasonForm = ({
  rejectionReason,
  setRejectionReason,
  index,
  removeReason,
  type,
}) => {
  const handleFieldChange = (newField) => {
    const updatedRejectionReason = { ...rejectionReason, ...newField };
    setRejectionReason(updatedRejectionReason, index);
  };

  return (
    <BaseCard id={`rejection-reason-form-container-${index}`} className="mb-4">
      <BaseCardHeader
        title={`Rejection Reason ${index + 1}`}
        className="!font-bold text-text-blue text-lg"
      >
        {!rejectionReason.id && (
          <Icon
            id={`remove-rejection-reasons-button-${index}`}
            icon="Cross"
            color="brand-blue"
            data-testid={`remove-rejection-reasons-button-${index}`}
            onClick={() => removeReason(index)}
          />
        )}
      </BaseCardHeader>
      <BaseCardBody withPadding>
        <InputField
          id={`code-${index}`}
          label="Code"
          placeholder="Enter Code"
          type="text"
          dataTestId={`rejection-reason-code-${index}`}
          value={rejectionReason.code || ""}
          required
          onChange={(e) => handleFieldChange({ code: e.target.value })}
        />
        <InputField
          id={`rejection-reason-name-${index}`}
          label="Rejection Reason"
          placeholder="Enter Rejection Reason"
          type="text"
          dataTestId={`rejection-reason-name-${index}`}
          value={rejectionReason.display_name || ""}
          required
          onChange={(e) => handleFieldChange({ display_name: e.target.value })}
        />
        {type === "authorization" && (
          <RadioField
            id={`rejection-reason-state-${index}`}
            className="w-full pl-0"
            inline
            label="State"
            labelClassName="!tracking-[1px] !uppercase"
            dataTestId={`rejection-reason-state-${index}`}
            value={rejectionReason.state}
            options={[
              { label: "Active", value: "active" },
              { label: "Archived", value: "archived" },
            ]}
            onChange={(e) => handleFieldChange({ state: e.target.value })}
          />
        )}
        <TextField
          id={`description-${index}`}
          label="Description"
          labelClassName="!tracking-[1px] !uppercase"
          placeholder="Enter Description"
          dataTestId={`rejection-reason-description-${index}`}
          value={rejectionReason.description || ""}
          onChange={(e) => handleFieldChange({ description: e.target.value })}
        />
      </BaseCardBody>
    </BaseCard>
  );
};

RejectionReasonForm.propTypes = {
  rejectionReason: PropTypes.shape({
    code: PropTypes.string,
    reason: PropTypes.string,
    state: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  setRejectionReason: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  removeReason: PropTypes.func,
  type: PropTypes.oneOf(["invoice", "authorization"]).isRequired,
};

RejectionReasonForm.defaultProps = {
  removeReason: () => {},
};

export default RejectionReasonForm;
