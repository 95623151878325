import { useCreateRecord, useUpdateRecord } from "api/apiHooks.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { useDefaultMutationConfig } from "api/apiUtils.js";
import { getJsonApiAdapter } from "api/apiService.js";

export const useCreateRejectionReason = (queryKey, mutationConfig) => {
  const { authToken } = useAuthContext();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useCreateRecord(queryKey, jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useUpdateRejectionReason = (queryKey, mutationConfig) => {
  const { authToken } = useAuthContext();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useUpdateRecord(queryKey, jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};
