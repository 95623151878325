export const TYPES_OF_REJECTION_REASONS = ["authorization", "invoice"];

export const DEFAULT_AUTHORIZATION_REJECTION_REASONS = [
  {
    code: "authorization_ineligible",
    display_name: "Not Eligible - Client does not meet eligibility criteria",
    state: "active",
    description:
      "Client must meet eligibility criteria in order for this service to be authorized",
  },
  {
    code: "authorization_unavailable",
    display_name: "Not Authorized - Funds/Service No Longer Available",
    state: "active",
    description: "This service can no longer be authorized",
  },
  {
    code: "authorization_cap",
    display_name: "Not Authorized - Service Cap / Limit Met",
    state: "active",
    description:
      "Client has reached the maximum dollar or unit amount allowed for the service",
  },
  {
    code: "authorization_missing_info",
    display_name: "Not Authorized - Needs More Information",
    state: "active",
    description: "Information missing from authorization request",
  },
  {
    code: "authorization_error",
    display_name: "Not Authorized - Request sent in error",
    state: "active",
    description: "Authorization request missing information",
  },
  {
    code: "authorization_other",
    display_name: "Not Authorized - Other",
    state: "active",
    description: "Other",
  },
];
