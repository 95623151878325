import React from "react";
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
} from "@unite-us/app-components";
import moment from "moment";
import { useOutletContext } from "react-router";

const FeeScheduleScreeningOverview = () => {
  const feeScheduleScreening = useOutletContext();

  return (
    <>
      <div data-testid="fee-schedule-screening-overview-base-card">
        <BaseCard>
          <BaseCardHeader
            title="Fee Schedule Screening Details"
            className="font-bold text-lg bg-medium-fill-grey"
          />
          <BaseCardBody className="p-4 bg-white">
            <div className="grid grid-cols-6">
              <div className="col-start-1 pl-0 pb-2 font-bold">Name:</div>
              <div>{feeScheduleScreening.name}</div>
              <div className="col-start-1 pl-0 pb-2 font-bold">Start Date:</div>
              <div className="capitalize">
                {moment
                  .utc(feeScheduleScreening.starts_at)
                  .format("MM/DD/YYYY")}
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">End Date:</div>
              <div className="capitalize">
                {moment.utc(feeScheduleScreening.ends_at).format("MM/DD/YYYY")}
              </div>
              <div className="col-start-1 pl-0 pb-2 font-bold">
                Unit Rate (Cents):
              </div>
              <div className="capitalize">{feeScheduleScreening.unit_rate}</div>
              <div className="col-start-1 pl-0 pb-2 font-bold">State:</div>
              <div className="capitalize">{feeScheduleScreening.state}</div>
              <div className="col-start-1 pl-0 pb-2 font-bold">Code Type:</div>
              <div className="capitalize">{feeScheduleScreening.code_type}</div>
              <div className="col-start-1 pl-0 font-bold">Code:</div>
              <div className="capitalize">{feeScheduleScreening.code}</div>
            </div>
          </BaseCardBody>
        </BaseCard>
      </div>
    </>
  );
};

export default FeeScheduleScreeningOverview;
