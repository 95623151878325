import React from "react";
import PropTypes from "prop-types";
import { Button } from "@unite-us/app-components";

const ArchiveModal = ({
  onConfirmArchive,
  onCancelArchive,
  itemName,
  itemType,
}) => {
  return (
    <div>
      <div>
        <p className="pb-2">You are about to archive the {itemType} below.</p>
        <ul className="list-disc list-inside py-4 text-sm">
          <li className="text-text-blue font-extrabold">{itemName}</li>
        </ul>
        <p className="pt-8">Would you like to archive the {itemType}?</p>
      </div>
      <div className="flex space-x-2 justify-end pt-4">
        <Button
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
          }
          label="Cancel"
          data-testid="archive-modal-cancel-button"
          onClick={onCancelArchive}
        />
        <Button
          className={
            "py-0 px-4 text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case h-9"
          }
          label="Confirm"
          data-testid="archive-modal-confirm-button"
          onClick={onConfirmArchive}
        />
      </div>
    </div>
  );
};

ArchiveModal.propTypes = {
  onCancelArchive: PropTypes.func.isRequired,
  onConfirmArchive: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
};

export default ArchiveModal;
