import React from "react";
import { useOutletContext } from "react-router";
import PlanEnrollments from "components/pages/Plan/PlanEnrollments";
import AttachmentsTable from "components/Metafields/AttachmentsTable";
import { useUpdatePlan } from "api/core/planHooks";

const PayerPlanEnrollmentsAttachments = () => {
  const plan = useOutletContext();
  const { updateRecord: updatePlan } = useUpdatePlan();

  return (
    <div data-testid="payer-plan-attachments-container">
      {plan.plan_type === "social" && <PlanEnrollments plan={plan} />}
      <AttachmentsTable entity={plan} updateEntity={updatePlan} />
    </div>
  );
};

export default PayerPlanEnrollmentsAttachments;
