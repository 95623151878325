import { isEmpty } from "lodash";

const getNonExceptionKey = (keys) => {
  return keys.find((key) => key !== "exception");
};

const getBatchJobDetails = (action, output, error) => {
  if (!isEmpty(error)) {
    const keys = Object.keys(error);
    if (keys.length === 0) return "";
    if (keys.length > 1) return error[getNonExceptionKey(keys)];
    return error["exception"]["exception_message"];
  }
  switch (action) {
    case "Invite Users":
      return `Invitation sent to ${output.email}.`;
    case "Update Users":
      return output.new_state
        ? `Status updated to ${output.new_state}.`
        : `Primary role updated to ${output.new_primary_role}.`;
    default:
      return "No details available";
  }
};

export default getBatchJobDetails;
