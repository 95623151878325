import React, { useRef } from "react";
import PropTypes from "prop-types";
import CustomTable from "components/Tables/CustomTable";
import parse from "html-react-parser";
import { Button } from "@unite-us/app-components";
import { generateUUID } from "utils/generateUUID";
import EditMetafields from "components/Metafields/EditMetafields";

const AttachmentsTable = ({ entity, updateEntity }) => {
  const editAttachmentsModal = useRef(null);

  const attachments = entity.metafields
    ? entity.metafields.filter((metafield) => metafield?.type === "file")
    : [];

  const attachmentTableHeaders = [
    { label: "Name" },
    { label: "Requirement" },
    { label: "Description" },
  ];

  const attachmentTableBody = attachments.map((attachment, index) => ({
    rowId: `attachment-${index}`,
    rowData: [
      { data: attachment.label },
      {
        data:
          attachment.validations && attachment.validations.required
            ? "Required"
            : "Optional",
      },
      { data: attachment.help_text ? parse(attachment.help_text) : "" },
    ],
  }));

  const openEditAttachmentsModal = () => {
    editAttachmentsModal.current.openDialog();
  };

  const editAttachmentConfirmationHandler = async (newMetafields) => {
    const notAttachmentsMetafields = entity.metafields.filter(
      (metafield) => metafield?.type !== "file",
    );
    const attachmentsMetafieldsPayload = newMetafields.map((metafield) => ({
      ...metafield,
      field: metafield.field || generateUUID(metafield.label),
    }));

    let response;
    try {
      response = await updateEntity(entity.id, {
        ...entity,
        metafields: [
          ...notAttachmentsMetafields,
          ...attachmentsMetafieldsPayload,
        ],
      });
    } catch (error) {
      console.error("Error updating attachments", error);
    }
    response && editAttachmentsModal.current.closeDialog();
  };

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-lg content-center">Attachments</h1>
        <Button
          id="edit-attachment-button"
          icon={{ name: "Edit", color: "text-blue", position: "left" }}
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
          }
          label="Edit Attachments"
          data-testid="edit-attachment-button"
          onClick={openEditAttachmentsModal}
        />
      </div>
      <CustomTable
        body={attachmentTableBody}
        headers={attachmentTableHeaders}
        isFetching={false}
        emptyTableMessage='Click "Edit Attachments" to add attachments'
        dataTestId="attachments-table"
        className="!mt-0 !h-fit"
        emptyTableClassName="py-4"
      />
      <EditMetafields
        metafields={attachments}
        modalRef={editAttachmentsModal}
        confirmationHandler={editAttachmentConfirmationHandler}
        isAttachment
      />
    </>
  );
};

AttachmentsTable.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    metafields: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string,
        label: PropTypes.string.isRequired,
        type: PropTypes.string,
        help_text: PropTypes.string,
        validations: PropTypes.shape({
          required: PropTypes.bool,
        }),
      }),
    ),
  }).isRequired,
  updateEntity: PropTypes.func.isRequired,
};

export default AttachmentsTable;
