import React from "react";
import { useNavigate, useParams } from "react-router";
import { useCreateFeeScheduleProgram } from "api/core/feeScheduleProgramHooks";
import { useUniteUsServices } from "api/core/serviceHooks";
import constructFeeScheduleProgramFormPayload from "./utils/constructFeeScheduleProgramFormPayload";
import getServiceTypesWithParents from "./utils/getServiceTypesWithParents";
import Spinner from "components/Spinner/Spinner";
import FeeScheduleProgramForm from "components/Forms/FeeScheduleProgramForm";
import { useFindFeeSchedule } from "api/core/feeScheduleHooks";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import { useToast } from "@unite-us/app-components";

const CreateFeeScheduleProgram = () => {
  const { addToast } = useToast();
  const { feeScheduleId } = useParams();
  const navigate = useNavigate();
  const {
    data: feeSchedule,
    isFetching: isFetchingFeeSchedule,
    isError: isErrorFeeSchedule,
  } = useFindFeeSchedule(feeScheduleId);
  const { createRecord: createFeeScheduleProgram } =
    useCreateFeeScheduleProgram({ onError: () => {} });
  const {
    data: parentServices,
    isFetching: isFetchingServices,
    isError: isErrorServices,
  } = useUniteUsServices({ filters: {} });
  const isFetching = isFetchingFeeSchedule || isFetchingServices;
  const isError = isErrorFeeSchedule || isErrorServices;

  const handleSubmit = async (feeScheduleProgram) => {
    const servicesWithParents = getServiceTypesWithParents(
      feeScheduleProgram.services,
      Object.values(parentServices),
    );
    const payload = constructFeeScheduleProgramFormPayload(
      feeScheduleProgram,
      feeScheduleId,
      servicesWithParents,
    );

    try {
      const response = await createFeeScheduleProgram(payload);
      const feeScheduleProgramId = response?.data?.data?.id || "";
      navigate(
        `/fee-schedules/${feeScheduleId}/fee-schedule-programs/${feeScheduleProgramId}`,
      );
    } catch (error) {
      console.error("Error creating fee schedule program: ", error);
      addToast({
        message: `Error creating fee schedule program: ${error?.response?.data?.errors[0]?.title || "Unknown error"}`,
        type: "error",
        dataTestId: "create-fee-schedule-program-error-toast",
      });
    }
  };

  return (
    <>
      {isFetching && (
        <Spinner
          className="h-screen"
          center
          dataTestId="create-fee-schedule-program-spinner"
        />
      )}
      {isError && (
        <ErrorMessage
          navigateUrl={`/fee-schedules/${feeScheduleId}`}
          errorText="Error Fetching Services"
          dataTestId="fee-schedule-program-create-error"
          navigateText="Go Back to Fee Schedule Page"
        />
      )}
      {!isError && !isFetching && (
        <FeeScheduleProgramForm
          onSubmit={handleSubmit}
          feeSchedule={feeSchedule}
          services={parentServices}
        />
      )}
    </>
  );
};

export default CreateFeeScheduleProgram;
