import React, { useState } from "react";
import { Button } from "@unite-us/app-components";
import { useProviders } from "api/core/providerHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import { useParams } from "react-router";

const CBOTable = () => {
  const feeScheduleProgramId = useParams().feeScheduleProgramId;
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: providersResponse,
    isFetching,
    isError,
  } = useProviders({
    filters: { fee_schedule_program: feeScheduleProgramId },
    include: "",
    pageSize,
    pageNumber,
  });
  const { data: providers, paging } = providersResponse || {};

  const tableHeaders = [{ label: "Name", className: "w-full" }];

  const tableBody = providers?.map((provider) => ({
    rowId: provider.id,
    rowData: [{ data: provider.name }],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-lg content-center">Community Based Organization</h1>
        <Button
          id="add-cbo-button"
          icon={{ name: "Add", color: "text-blue", position: "left" }}
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
          }
          label="Add CBO"
          data-testid="add-cbo-button"
          disabled
        />
      </div>
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        dataTestId="fee-schedule-program-cbo-table"
        emptyTableMessage='Click "Add CBO" to add CBOs'
        errorMessage="Error fetching CBOs."
        className="!h-fit"
        emptyTableClassName="py-4"
      />
    </>
  );
};

export default CBOTable;
