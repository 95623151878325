import { useToast } from "@unite-us/app-components";
import { useInvalidateQueries } from "api/apiHooks.js";
import {
  useCreateRejectionReason,
  useUpdateRejectionReason,
} from "api/core/rejectionReasonHooks.js";
import { capitalize } from "lodash";

export const useSubmitRejectionReasons = ({
  queryKey,
  feeScheduleId,
  type,
}) => {
  const { addToast } = useToast();
  const invalidateQueries = useInvalidateQueries();
  const { createRecord: createRejectionReason } = useCreateRejectionReason(
    queryKey,
    { onError: () => {} },
  );
  const { updateRecord: updateRejectionReason } = useUpdateRejectionReason(
    queryKey,
    { onError: () => {} },
  );

  const submitRejectionReasons = async (updatedRejectionReasons) => {
    if (!updatedRejectionReasons || updatedRejectionReasons.length === 0)
      return [];

    const existingReasons = updatedRejectionReasons.filter(
      (reason) => reason.id,
    );
    const newReasons = updatedRejectionReasons
      .filter((reason) => !reason.id)
      .map((reason) => ({ ...reason, fee_schedule: feeScheduleId }));

    const preparePayload = (reason) => {
      const { isEdited, ...cleanedReason } = reason;
      return cleanedReason;
    };

    const handleResult = (result, reason, action) => {
      if (result.status === "rejected") {
        addToast({
          message: `Error ${action} ${type} rejection reason "${reason.display_name || "unknown"}": ${result.reason.message || "Unknown error"}`,
          type: "error",
          dataTestId: `error-${action}-${type}-rejection-reason-toast`,
        });
        return reason;
      }
      return null;
    };

    const createResults = await Promise.allSettled(
      newReasons.map((reason) => createRejectionReason(preparePayload(reason))),
    );
    const updateResults = await Promise.allSettled(
      existingReasons.map((reason) =>
        updateRejectionReason(reason.id, preparePayload(reason)),
      ),
    );

    const failedRejectionReasons = [
      ...createResults
        .map((res, index) => handleResult(res, newReasons[index], "creating"))
        .filter(Boolean),
      ...updateResults
        .map((res, index) =>
          handleResult(res, existingReasons[index], "updating"),
        )
        .filter(Boolean),
    ];

    if (failedRejectionReasons.length === 0) {
      invalidateQueries(queryKey);
      addToast({
        message: `${capitalize(type)} Rejection Reasons successfully updated.`,
        type: "success",
        dataTestId: `update-${type}-rejection-reasons-success-toast`,
      });
    }

    return failedRejectionReasons;
  };

  return { submitRejectionReasons };
};
