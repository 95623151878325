import React from "react";
import PropTypes from "prop-types";
import { InputField } from "@unite-us/ui";
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  RadioField,
  TextField,
} from "@unite-us/app-components";

const MetafieldForm = ({ metafield, setMetafield, index, isAttachment }) => {
  const requirementOptions = [
    { label: "Required", value: true },
    { label: "Optional", value: false },
  ];

  const handleMetaFieldChange = (newField) => {
    const newMetafield = { ...metafield, ...newField };
    if (newMetafield.help_text === "") {
      delete newMetafield.help_text;
    }
    setMetafield(newMetafield, index);
  };

  return (
    <BaseCard className="mb-4">
      <BaseCardHeader
        title={
          isAttachment ? `Attachment ${index + 1}` : `Custom Field ${index + 1}`
        }
        className="!font-bold text-text-blue text-lg"
      />
      <BaseCardBody withPadding>
        <InputField
          id={`field-name-${index}`}
          label="Field Name"
          placeholder="Enter the name of the field"
          type="text"
          value={metafield.label}
          required
          dataTestId={`metafield-name-${index}`}
          onChange={(e) => {
            handleMetaFieldChange({
              label: e.target.value,
            });
          }}
        />
        <div className={"flex justify-between"}>
          <RadioField
            id={`required-${index}`}
            inline
            label="Field Requirement"
            labelClassName="!text-xs !text-[#506784] tracking-[1px] !leading-[18px] font-heavy-font !uppercase"
            options={requirementOptions}
            value={
              metafield.validations ? metafield.validations.required : false
            }
            dataTestId={`metafield-requirement-${index}`}
            onChange={(e) => {
              handleMetaFieldChange({
                validations: {
                  ...metafield.validations,
                  required: e.target.value === "true",
                },
              });
            }}
          />
        </div>
        <TextField
          id={`description-${index}`}
          label="Description"
          labelClassName="!text-xs !text-[#506784] tracking-[1px] !leading-[18px] font-heavy-font !uppercase"
          placeholder="Type Description here."
          dataTestId={`metafield-description-${index}`}
          value={metafield.help_text ? metafield.help_text : ""}
          onChange={(e) => handleMetaFieldChange({ help_text: e.target.value })}
        />
      </BaseCardBody>
    </BaseCard>
  );
};

MetafieldForm.propTypes = {
  metafield: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    help_text: PropTypes.string,
    validations: PropTypes.shape({
      required: PropTypes.bool,
    }),
  }).isRequired,
  setMetafield: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isAttachment: PropTypes.bool,
};

MetafieldForm.defaultProps = {
  isAttachment: false,
};

export default MetafieldForm;
