import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "@unite-us/app-components";
import {
  DEFAULT_AUTHORIZATION_REJECTION_REASONS,
  TYPES_OF_REJECTION_REASONS,
} from "./utils/constants";
import { isEqual } from "lodash";
import { useToast } from "@unite-us/app-components";
import RejectionReasonForm from "./RejectionReasonForm";

const EditRejectionReasons = ({
  reasons,
  modalRef,
  submitRejectionReasons,
  closeModal,
  type,
  addAllStandardRejectionReasons,
}) => {
  const [newReasons, setNewReasons] = useState(reasons || []);
  const { addToast } = useToast();

  useEffect(() => {
    if (type === "authorization" && addAllStandardRejectionReasons) {
      const newReasonsToAdd = DEFAULT_AUTHORIZATION_REJECTION_REASONS.map(
        (reason) => ({ ...reason, isEdited: true }),
      );
      setNewReasons([...reasons, ...newReasonsToAdd]);
    }
  }, [addAllStandardRejectionReasons, type, reasons]);

  const cancelHandler = () => {
    setNewReasons([...reasons]);
    closeModal();
  };

  const areReasonsEqual = (a, b) => {
    if ((a.id && !b.id) || (!a.id && b.id)) return false;

    if (a.id && b.id) {
      return a.id === b.id;
    }

    // Removing FeeScheduleId from comparison since the id is added on submitRejectionReasons
    const { fee_schedule: feeScheduleA, ...noFeeScheduleIdA } = a;
    const { fee_schedule: feeScheduleB, ...noFeeScheduleIdB } = b;

    return isEqual(noFeeScheduleIdA, noFeeScheduleIdB);
  };

  const submitHandler = async () => {
    const incompleteRejectionReasons = newReasons.filter(
      (reason) => !reason.display_name || !reason.code,
    );

    if (incompleteRejectionReasons.length > 0) {
      addToast({
        message: "Please fill out all required fields.",
        type: "error",
        dataTestId: `incomplete-${type}-rejection-reasons-toast`,
      });
      return;
    }

    const editedReasons = newReasons.filter((reason) => reason.isEdited);
    const failedRejectionReasons = await submitRejectionReasons(editedReasons);

    if (failedRejectionReasons.length === 0) {
      closeModal();
      return;
    }

    setNewReasons((prevReasons) =>
      prevReasons.map((reason) => {
        const failed = failedRejectionReasons.some((failedReason) =>
          areReasonsEqual(reason, failedReason),
        );
        if (failed) return reason;

        const { isEdited, ...cleanedReason } = reason;
        return cleanedReason;
      }),
    );
  };

  const setNewReason = (editedReason, index) => {
    const editedReasons = [...newReasons];
    editedReasons[index] = { ...editedReason, isEdited: true };
    setNewReasons(editedReasons);
  };

  const addReason = () => {
    const updatedReasons = [...newReasons];
    const newReason =
      type === "authorization"
        ? { state: "active", isEdited: true }
        : { isEdited: true };
    updatedReasons.push(newReason);
    setNewReasons(updatedReasons);
  };

  const removeNewReason = (index) => {
    const updatedReasons = [...newReasons];
    updatedReasons.splice(index, 1);
    setNewReasons(updatedReasons);
  };

  const isSaveDisabled = !newReasons.some((reason) => reason.isEdited);

  const Footer = () => (
    <div className="flex justify-end space-x-6">
      <Button
        id="cancel-rejection-reasons-button"
        className="text-sm text-brand-blue rounded border solid border-med-pale-blue bg-white normal-case"
        label="Cancel"
        data-testid="cancel-rejection-reasons-button"
        onClick={cancelHandler}
      />
      <Button
        id="save-rejection-reasons-button"
        className="text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case"
        label="Save"
        data-testid="save-rejection-reasons-button"
        onClick={submitHandler}
        disabled={isSaveDisabled}
      />
    </div>
  );

  return (
    <Modal
      ref={modalRef}
      modalContentStyles="flex-auto overflow-y-auto bg-medium-fill-blue"
      title="Add/Edit Rejection Reasons"
      data-testid="add-edit-rejection-reasons-modal"
      spinnerShow={false}
      onRequestClose={cancelHandler}
      modal
      actions={<Footer />}
      size="small"
      focusTrap
      customSize={{
        maxHeight: "100%",
        height: "100%",
        left: "auto",
        right: "0",
        transform: "translateY(-50%)",
        width: "450px",
      }}
    >
      {newReasons?.map((reason, index) => (
        <RejectionReasonForm
          key={`rejection-reason-form-${index}`}
          rejectionReason={reason}
          setRejectionReason={setNewReason}
          index={index}
          removeReason={removeNewReason}
          type={type}
        />
      ))}
      <Button
        id="add-rejection-reason-button"
        icon={{ name: "Add", color: "text-blue", position: "left" }}
        className="py-0 px-4 text-sm !text-text-blue rounded border solid border-light-border-blue bg-white normal-case h-9 w-fit"
        label="Add Rejection Reason"
        onClick={() => addReason()}
      />
    </Modal>
  );
};

EditRejectionReasons.propTypes = {
  reasons: PropTypes.array.isRequired,
  modalRef: PropTypes.object.isRequired,
  submitRejectionReasons: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  type: PropTypes.oneOf(TYPES_OF_REJECTION_REASONS).isRequired,
  addAllStandardRejectionReasons: PropTypes.bool,
};

EditRejectionReasons.defaultProps = {
  addAllStandardRejectionReasons: false,
};

export default EditRejectionReasons;
