import { useFind } from "api/apiHooks.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useDefaultPagingQueryConfig } from "api/apiUtils.js";

export const useAuthorizationRejectionReasons = ({
  filters = {},
  include,
  pageSize,
  pageNumber,
  sort,
}) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind(
    "service_authorization_denial_reason",
    { ...filters },
    jsonApiAdapter,
    {
      queryConfig: { ...defaultPagingQueryConfig },
      page: { number: pageNumber || 1, size: pageSize || 15 },
      sort: sort || "",
      include: include || "",
    },
  );
};
