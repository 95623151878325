import React from "react";
import { useFindFeeScheduleProgram } from "api/core/feeScheduleProgramHooks";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import FeeScheduleProgramHeader from "./components/FeeScheduleProgramHeader";
import { useFindFeeSchedule } from "api/core/feeScheduleHooks";
import { Outlet, useParams } from "react-router";

const FeeScheduleProgram = () => {
  const { feeScheduleProgramId, feeScheduleId } = useParams();

  const {
    data: feeScheduleProgram,
    isFetching: isFetchingFeeScheduleProgram,
    isError: isErrorFeeScheduleProgram,
  } = useFindFeeScheduleProgram(feeScheduleProgramId);

  const {
    data: feeSchedule,
    isFetching: isFetchingFeeSchedule,
    isError: isErrorFeeSchedule,
  } = useFindFeeSchedule(feeScheduleId);

  const shouldRenderTabComponent =
    !isFetchingFeeScheduleProgram && !isErrorFeeScheduleProgram;
  const crumbs = [
    { label: "Fee Schedules", href: "/fee-schedules" },
    { label: feeSchedule?.name, href: `/fee-schedules/${feeScheduleId}` },
    { label: feeScheduleProgram?.name },
  ];

  const isFetching = isFetchingFeeSchedule || isFetchingFeeScheduleProgram;
  const isError = isErrorFeeSchedule || isErrorFeeScheduleProgram;

  return (
    <>
      <div className="h-full flex flex-grow-0 flex-col">
        {!isError && !isFetching && (
          <FeeScheduleProgramHeader
            feeScheduleProgram={feeScheduleProgram}
            feeScheduleId={feeScheduleId}
            crumbs={crumbs}
          />
        )}
        <div className="pt-8 pb-10 px-20 h-full flex flex-grow-0 flex-col overflow-y-auto">
          {isFetching && <Spinner center />}
          {isError && (
            <ErrorMessage
              dataTestId="fee-schedule-program-error"
              errorText="Error Fetching Fee Schedule Program"
              navigateUrl={`/fee-schedules/${feeScheduleId}/`}
              navigateText="Go Back to Fee Schedule Page"
            />
          )}
          {shouldRenderTabComponent && (
            <Outlet context={feeScheduleProgram || {}} />
          )}
        </div>
      </div>
    </>
  );
};

export default FeeScheduleProgram;
