import React, { useRef, useEffect, useState } from "react";
import { useInvoiceRejectionReasons } from "api/core/invoiceRejectionReasonHooks";
import CustomTable from "components/Tables/CustomTable";
import { Button } from "@unite-us/app-components";
import { useParams } from "react-router";
import EditRejectionReasons from "components/RejectionReasons/EditRejectionReasons";
import { useSubmitRejectionReasons } from "hooks/useSubmitRejectionReasons";

const InvoiceRejectionReasons = () => {
  const feeScheduleId = useParams().feeScheduleId;
  const editRejectionReasonsModal = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Will be modified in the future by https://uniteus.atlassian.net/browse/TOOL-1216
  useEffect(() => {
    if (isModalOpen) {
      editRejectionReasonsModal.current?.openModal();
    } else {
      editRejectionReasonsModal.current?.closeModal();
    }
  }, [isModalOpen]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const {
    data: invoiceRejectionReasonsResponse,
    isFetching,
    isError,
  } = useInvoiceRejectionReasons({
    filters: { fee_schedule: feeScheduleId },
    include: "",
  });
  const { data: rejectionReasons } = invoiceRejectionReasonsResponse || {};

  const { submitRejectionReasons } = useSubmitRejectionReasons({
    queryKey: "invoice_rejection_reason",
    feeScheduleId,
    type: "invoice",
  });

  const tableHeaders = [
    { label: "Code", className: "w-1/6 border-r" },
    { label: "Rejection Reason", className: "w-2/5" },
    { label: "Description", className: "w-2/5" },
  ];

  const tableBody = rejectionReasons?.map((rejectionReason) => ({
    rowId: rejectionReason.id,
    rowData: [
      { data: rejectionReason.code, className: "border-r" },
      { data: rejectionReason.display_name },
      { data: rejectionReason.description },
    ],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-xl content-center">Invoice Rejection Reasons</h1>
        <Button
          id="edit-invoice-rejection-reasons-button"
          icon={{ name: "Edit", color: "text-blue", position: "left" }}
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
          }
          label="Edit Reasons"
          data-testid="edit-invoice-rejection-reasons-button"
          onClick={openModal}
        />
      </div>
      <CustomTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        dataTestId="invoice-rejection-reasons-table"
        onRowClick={() => {}}
        emptyTableMessage={`No rejection reasons have been configured for this fee schedule.\nClick "Edit Reasons" to add rejection reasons.`}
        emptyTableClassName="max-h-40"
        showHeaderOnEmptyTable={false}
        errorMessage="Error fetching Invoice Rejection Reasons."
        className={tableBody?.length !== 0 ? "!h-fit" : ""}
      />
      {isModalOpen && (
        <EditRejectionReasons
          reasons={rejectionReasons}
          modalRef={editRejectionReasonsModal}
          submitRejectionReasons={submitRejectionReasons}
          closeModal={closeModal}
          type={"invoice"}
        />
      )}
    </>
  );
};

export default InvoiceRejectionReasons;
