import { has } from "lodash";

export const trimAndNormalizeSpaces = (str) => {
  return str.trim().replace(/\s\s+/g, " ");
};

export const getCommaSeparatedListLength = (arrayString) => {
  if (!arrayString) return 0;
  return arrayString
    .split(",")
    .map((item) => item.trim())
    .filter(Boolean).length;
};

export const getSimpleHash = (...str) => {
  let hash = 0;
  // Combine all input strings into a single string
  let joinedStr = str.join("");
  for (let i = 0; i < joinedStr.length; i++) {
    // Get the ASCII code of the current character
    const char = joinedStr.charCodeAt(i);
    // Update hash using the djb2 algorithm:
    // - Shift left by 5 (multiply by 32)
    // - Subtract the previous hash value
    // - Add the current character's ASCII code
    hash = (hash << 5) - hash + char;
    // Force hash to 32-bit integer using bitwise OR
    // This handles potential integer overflow
    hash |= 0;
  }
  // Convert the final hash value to string and return
  return hash.toString();
};
